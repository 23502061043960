import { FORM_PAYER_TYPE, FORMULATE_DOCUMENTS_STATUSES } from '~/utils/dictionaries';
import { amountToNumber } from '~/utils/intl-converter';

const STEPS = [
	{
		id: 0, name: 'login', title: 'Логин / регистрация', completed: true,
	},
	{
		id: 1, name: 'info', title: 'Основная информация', completed: true,
	},
	{
		id: 2, name: 'documents', title: 'Документы', completed: false,
	},
];

const STEPS_ID = {
	PAYMENT: 3,
	FINISH: 4,
	RESET: 5,
};

export function state() {
	return {
		isAuth: false,
		currentStep: 'login',
		redirectStep: null,
		isLoading: false,
		orderHash: '',
		isCompletedFieldsDisabled: false,
		isStepError: false,
		isVisiblePromocodeModal: false,
		currentProduct: {},
		personData: {},
		price: {
			initial: 0,
			withVat: 0,
			withPromocode: null,
			withLoyalty: null,
		},
		currentPromocode: {
			isActive: false,
			value: '',
		},
		form: {
			payer: {
				type: FORM_PAYER_TYPE.PHYSICAL,
			},
			documents: {
				snils_document: {},
				passport_document: {},
				education_document: {},
			},
		},
		order: {
			data: {
				products: [],
			},
		},
	};
}

export const mutations = {
	setForm(state, stepData) {
		state.form = { ...state.form, ...stepData };
	},
	setStep(state, step) {
		state.currentStep = step;
		state.redirectStep = null;
		state.isStepError = false;
	},
	setLoading(state, value) {
		state.isLoading = value;
	},
	setPayerType(state, value) {
		state.form.payer = { ...state.form.payer, type: value };
	},
	setPrice(state, payload) {
		if (payload.value === null) {
			state.price[payload.key] = null;
			return;
		}
		state.price[payload.key] = amountToNumber(payload.value);
	},
	setRedirectStep(state, value) {
		state.redirectStep = value;
	},
	setOrderHash(state, value) {
		state.orderHash = value;
	},
	setProduct(state, value) {
		state.currentProduct = value;
	},
	setFieldsDisabled(state, value) {
		state.isCompletedFieldsDisabled = value;
	},
	setPersonData(state, value) {
		state.personData = value;
	},
	setStepError(state, value) {
		state.isStepError = value;
	},
	setPromocode(state, value) {
		state.currentPromocode = { ...state.currentPromocode, ...value };
	},
	setVisibilityPromocodeModal(state, value) {
		state.isVisiblePromocodeModal = value;
	},
};

export const getters = {
	baseForm: (state) => state.form,
	payerType: (state) => state.form.payer.type,
	isPhysicalPayer(state, getters) {
		return getters.payerType === FORM_PAYER_TYPE.PHYSICAL;
	},
	isIpPayer(state, getters) {
		return getters.payerType === FORM_PAYER_TYPE.IP;
	},
	isRepresentative(state) {
		return state.form?.is_representative || state.personData?.is_representative;
	},
	isHideDocumentsStep(state, getters) {
		const { isRepresentative, isPhysicalPayer } = getters;
		return isRepresentative || !isPhysicalPayer;
	},
	isNoEducation(state) {
		const {
			no_education: noEducation,
			documents: { education_document: educationDocument },
		} = state.form;
		const studentsNoEduction = state.form.students?.some((student) => +student.no_education);

		return Number((
			noEducation
				|| educationDocument?.no_education
				|| state.personData.no_education
				|| studentsNoEduction
		) || 0);
	},
	stepsList(state, getters) {
		const { isHideDocumentsStep, isPhysicalPayer } = getters;
		const companyStep = {
			id: 2,
			name: 'company',
			title: 'Сведения о компании',
			completed: false,
		};
		const paymentStep = {
			id: 3,
			name: 'payment',
			title: (isPhysicalPayer ? 'Способ оплаты' : 'Оплатить по счету'),
			completed: false,
		};
		const currentSteps = isPhysicalPayer
			? [...STEPS, paymentStep]
			: [...STEPS, companyStep, paymentStep];

		const formattedSteps = currentSteps
			.filter((step) => (isHideDocumentsStep ? step.name !== 'documents' : true));
		return formattedSteps.map((step) => ({
			...step,
			completed: state.personData.form_step > step.id,
			blocked: !state.orderHash,
		}));
	},
	initialPrice(state, getters) {
		return getters.isNoEducation ? state.price.withVat : state.price.initial;
	},
	totalPrice(state, getters) {
		if (state.price.withPromocode === 0 || state.price.withPromocode > 0) {
			return state.price.withPromocode;
		}
		if (!getters.isPhysicalPayer && state.orderHash) {
			return state.price.initial;
		}
		const { isNoEducation, isRepresentative } = getters;
		const { initial, withVat } = state.price;
		let {
			price: currentProductPrice,
			program_price_with_vat: programPriceWithVat,
		} = state.currentProduct;
		if (currentProductPrice) {
			currentProductPrice = amountToNumber(currentProductPrice);
			programPriceWithVat = amountToNumber(programPriceWithVat);
		}

		const dynamicPrice = +currentProductPrice || +initial;
		const priceWithVat = +programPriceWithVat || +withVat;

		if (state.form.students?.length) {
			// eslint-disable-next-line no-nested-ternary
			const startAccPrice = isRepresentative
				? 0
				: (isNoEducation ? priceWithVat : dynamicPrice);

			return state.form.students.reduce((acc, student) => {
				const price = +student.no_education ? priceWithVat : +dynamicPrice;

				return acc + price;
			}, startAccPrice);
		}

		if (isNoEducation) return priceWithVat;

		return dynamicPrice || 0;
	},
	currentRedirectStep(state) {
		return state.redirectStep;
	},
	isDocumentsVerified(state) {
		return Object.values(state.form.documents)
			.every((doc) => doc.verification_status === FORMULATE_DOCUMENTS_STATUSES.ACCEPTED);
	},
	isFreePromocode(state) {
		return state.price.withPromocode === 0;
	},
};

export const actions = {
	async getPersonData({ commit, getters, state }, scenarioId) {
		try {
			const { data } = await this.$api.getPersonOnPayment(scenarioId);
			const {
				form_step: stepId,
				order_hash: hash,
				payer_type: payerType,
			} = data;

			commit('setPersonData', data);
			if (stepId === STEPS_ID.FINISH) {
				commit('setStep', 'finish');
				commit('setOrderHash', hash);
				return;
			}
			if (state.currentStep === 'finish') return;
			if (payerType) commit('setPayerType', payerType);
			commit('setOrderHash', hash);

			const currentStepName = getters.stepsList.find((step) => step.id === stepId)?.name;

			if (currentStepName) {
				commit('setStep', currentStepName);
			} else {
				commit('setStep', 'info');
			}
		} catch (e) {
			console.error(e);
		}
	},
	async setCurrentStep({ commit, state, getters }, name) {
		commit('setStepError', false);
		try {
			const isResetStep = name === 'reset';
			const isFinishStep = name === 'finish';
			const stepId = getters.stepsList.find((step) => step.name === name)?.id;

			commit('setStep', name);

			if (stepId > state.personData.form_step) {
				await this.$api.updateFormStep(state.orderHash, stepId);
			}
			if (isResetStep) {
				commit('setStep', 'info');
				await this.$api.updateFormStep(state.orderHash, STEPS_ID.RESET);
			}
			if (isFinishStep) {
				commit('setStep', 'finish');
				await this.$api.updateFormStep(state.orderHash, STEPS_ID.FINISH);
			}
		} catch (e) {
			console.error(e);
		}
	},
	async resetForm({ commit, state }) {
		try {
			await this.$api.resetForm(state.orderHash);
			commit('setPersonData', {});
			commit('setOrderHash', '');
			commit('setStep', 'info');
		} catch (e) {
			console.error(e);
		}
	},
	async resetPromocode({ commit, state }) {
		try {
			await this.$api.resetForm(state.orderHash);
			commit('setPersonData', {});
			commit('setOrderHash', '');
			commit('setStep', 'info');
		} catch (e) {
			console.error(e);
		}
	},
};
